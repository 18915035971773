.card {
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius-lg;

  label {
    font-size: 0.875rem;
  }

  &__header {
    flex-shrink: 0;
    margin-bottom: $grid-gutter-width;
    background: theme-color("primary");
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    text-align: center;
    color: $white;

    h2,
    h3 {
      margin-bottom: 0;
      color: $white;
    }
  }

  &__footer {
    flex-shrink: 0;
  }

  &__body {
    flex: 1 1 auto;
    margin-top: bottom;
  }
}
