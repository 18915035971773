.navigation {
  box-shadow: $box-shadow;
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;

  @include media-breakpoint-down(xs) {
    position: static;

    .container {
      padding: 0;
    }
  }

  background: $white;

  ul {
    margin: 0;
  }

  li {
    margin: 0;
  }

  a {
    padding: 0.715rem;
    display: block;
    color: $gray-900;
    text-decoration: none;
  }

  &__main {
    a {
      position: relative;
      font-weight: $font-weight-bold;
      @include media-breakpoint-up(sm) {
        padding: 1rem 2rem;
      }

      &:hover {
        color: theme-color("secondary");
      }
      &.is-active {
        background: $gray-200;
      }
    }
  }

  &__lang {
    background: $white;

    @include media-breakpoint-up(sm) {
      background: transparent;
    }

    a {
      padding: 0.715rem 0.5rem;
      opacity: 0.5;

      &.is-active,
      &:hover {
        opacity: 1;
      }
    }
  }
}
