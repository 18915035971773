.footer {
  font-size: 0.875rem;

  &__list {
    li {
      &::before {
        content: "\2014";
        margin-right: 0.5em;
        color: theme-color("secondary");
      }
    }
  }

  &__bottom {
    font-size: 0.815rem;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid $gray-300;
    color: $gray-600;
  }
}
