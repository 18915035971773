.header {
  font-size: 0.875rem;
  // background: linear-gradient(to right, #0d1f4d, #3a1b53);
  background: darken(theme-color("primary"), 10%);
  color: rgba(255, 255, 255, 0.5);

  a {
    color: $white;
  }

  @include media-breakpoint-down(xs) {
    font-size: 0.815rem;
  }
}
