.container {
  max-width: 80rem;

  @include media-breakpoint-up(md) {
    padding: 0 $grid-gutter-width;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 $grid-gutter-width * 1.5;
  }
}
