.hero {
  text-align: center;

  h1 {
    @include media-breakpoint-up(xs) {
      font-size: 1.4rem;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.6rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
  }

  h4 {
    text-transform: uppercase;
    font-size: $font-size-sm;
    letter-spacing: 0.3em;
    color: $gray-600;
  }

  p {
    @include media-breakpoint-down(xs) {
      font-size: 0.815rem;
    }
  }

  .underline {
    &::after {
      content: "";
      width: 4em;
      max-width: 4rem;
      height: 2px;
      margin-top: 0.4em;
      margin-left: auto;
      margin-right: auto;
      background: theme-color("danger");
      display: block;
    }
  }

  a {
    color: $body-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
